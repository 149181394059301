/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
// import main from './main.png';
import logo from './logojuarez.png';
import logoMain from './main.png';
import burger from './burger.svg';
import './App.css';
import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const LoadingComponent = () => {
  const [switcher, setSwitcher] = useState(true);
  setTimeout(() => {
    setSwitcher(false);
  }, 2000);
  return (
    <section className="loader-wrapper">
      <div className="loader-semi">
        <div data-aos="fade-up">
          <div className="loader"></div>
        </div>
        <img src={logo} alt="logo" className="logo-loader" data-aos="fade-up" />
      </div>
    </section>
  );
};

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  const [isLoading, setIsloading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setVisible((v) => !v);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 2500);
    setTimeout(() => {
      handleClose();
    }, 4000);
    return () => {};
  }, []);

  return !isLoading ? (
    <div className="App">
      {isOpen && (
        <div className="modal" onClick={toggleMenu}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-btn2" onClick={toggleMenu}>
              &times;
            </span>
            <div>
              <img
                src={logo}
                alt="logo"
                className="logo-loader"
                data-aos="fade-right"
                data-aos-duration="100"
                data-aos-delay="200"
              />
            </div>
            <ul>
              <li
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="200"
                className="nav-header"
              >
                <a href="#about" className="white" onClick={toggleMenu}>
                  Acerca de mí
                </a>
              </li>
              <li
                data-aos="fade-right"
                data-aos-duration="400"
                data-aos-delay="200"
                className="nav-header"
              >
                <a href="#services" className="white" onClick={toggleMenu}>
                  Servicios
                </a>
              </li>
              <li
                data-aos="fade-right"
                data-aos-duration="500"
                data-aos-delay="200"
                className="nav-header"
              >
                <a href="#contact" className="white" onClick={toggleMenu}>
                  Contacto
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      {visible && (
        <div
          className="notification-wrp"
          data-aos="flip-right"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="5000"
          data-aos-delay="1500"
        >
          <div className="notification">
            <button
              onClick={handleClose}
              className="close-btn"
              data-aos="zoom-out"
              data-aos-duration="5000"
              data-aos-delay="1500"
            >
              &times;
            </button>
            <p className="entrevista-cls">
              ¡Hola! Soy <strong>Jonantan Juárez</strong>. Me encantaría conocer tu opinión acerca
              de mi trabajo.
              <a
                className="link-cls"
                href="https://docs.google.com/forms/d/e/1FAIpQLScuN-M7vO76mCsuInvN_mel1LZHYW8AtqD_KmgCP1XDzknQxw/viewform?usp=sf_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Haz clic aquí
              </a>{' '}
              para completar una breve encuesta.
            </p>
          </div>
        </div>
      )}
      <div className="actions-container">
        <nav className="actions" data-aos="zoom-out-down">
          <div>
            <img src={logoMain} alt="logo" className="logo-main" />
          </div>
          {!isOpen && (
            <div className="flexer">
              <div className="navbers">
                <a
                  href="#acerca-de-mí"
                  className="white subheader"
                  onClick={null}
                  data-aos="fade-down"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="5000"
                  data-aos-delay="500"
                >
                  Acerca de mí
                </a>
                <a
                  href="#servicios"
                  className="white"
                  onClick={null}
                  data-aos="fade-down"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="4000"
                  data-aos-delay="600"
                >
                  Servicios
                </a>
                <a
                  href="#contacto"
                  className="white"
                  onClick={null}
                  data-aos="fade-down"
                  data-aos-offset="100"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="4000"
                  data-aos-delay="700"
                >
                  Contacto
                </a>
              </div>
              <a href="tel:+50685076286" className="actions-btn">
                Cotizar Ahora
              </a>
              <button type="button" className="burger-btn" onClick={toggleMenu}>
                <img src={burger} alt="logo" className="burger-main" />
              </button>
            </div>
          )}
        </nav>
      </div>
      <header className="App-header">
        <h1 data-aos="zoom-in" className="title animated-text2">
          Marca Personal Jonantan Juárez
        </h1>
        <span data-aos="zoom-out" className="animated-text">
          En progreso
        </span>
      </header>
    </div>
  ) : (
    <LoadingComponent />
  );
}

export default App;
